@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --bg-colog: #FFFFFF;
    --text-color: #282828;
    --text-size: 16px;
    --h-color: #282828;
    --link-color: #46A4F6;
    --badge-bg: #f3f6fa;
    --bs-secondary-color: #4f5352;
    --text-font: DM Mono, monospace;
    --title-font: Inter-BlackItalic, Inter, sans-serif;
    --number-font: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    --bs-gutter-x: 1.5rem;
}

body {
    font-size: var(--text-size);
}

p, a, li, .p, .a, .li {
    font-family: var(--text-font);
    font-size: var(--text-size);
    font-style: normal;
    font-weight: 400;
    margin: 0;
    color: var(--text-color);
    transition: all .2s ease;
}

small, input, label {
    font-family: var(--text-font);
    color: var(--text-color);
}

.btn {
    font-family: var(--text-font);
    font-size: var(--text-size);
}

a.btn:hover {
    text-decoration: none;
}

a:hover, .btn-link:hover {
    color: var(--link-color);
    text-decoration: underline;
    text-decoration-color: var(--link-color);
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: var(--h-color);
    font-style: italic;
    font-weight: 900;
    margin: 0;
}

h1, .h1 {
    font-size: calc(var(--text-size) * 3);
}

h2, .h2 {
    font-size: calc(var(--text-size) * 2);
}

h3, .h3 {
    font-size: calc(var(--text-size) + 8);
    font-weight: 700;
    font-style: normal;
}

.container {
    max-width: 630px;
}

.payments-list .payment-item {
    cursor: pointer;
    text-decoration: none;
}

.payments-list .payment-item:hover {
    background-color: var(--bs-border-color);
    transition: all .15s;
}

header {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100px;
    justify-content: space-between;
    width: 100%;
    z-index: 100
}

.logo, .logo-img {
    width: 50px;
    height: 50px;
    text-decoration: none;
}

.bg-muted {
    background-color: var(--badge-bg);
}

.card-img-top {
    height: 190px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.numbers {
    font-family: var(--number-font);
}

#alert-container {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1050;
    width: 100%;
    max-width: 425px;
    padding-left: calc(var(--bs-gutter-x) * .5);
    padding-right: calc(var(--bs-gutter-x) * .5);
}
